import { Link } from "gatsby"
import React from "react"

import {
  Text,
  TextType,
  TextFontSize,
  TextFontWeight,
} from "../components/Text"

import "./page.styles.scss"

const PrivacyPolicy = () => {
  return (
    <div className={"page"}>
      <Text
        type={TextType.H1}
        size={TextFontSize.huge}
        weight={TextFontWeight.bold}
      >
        Privacy policy
      </Text>
      <Text type={TextType.PARAGRAPH}>
        At <Link to="/">hello-js.com</Link> Website, one of our
        main priorities is the privacy of our visitors.
        This <b>Privacy Policy</b> document is designed to help you understand how we
        collect, use and safeguard the information you provide to us.
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        1. Agreement
      </Text>

      <Text type={TextType.PARAGRAPH}>
        By accessing our website, you accept our Privacy Policy.
      </Text>
      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        2. Definitions
      </Text>
      <ul className={"page__list"}>
        <li>
          <b>“Service” / “Website”</b> - The hello-js.com website
        </li>
        <li>
          <b>“Administrator”</b> - The owner of the hello-js.com Service<br />
          Michał Rożenek<br />
          ul. Radlińskie Chałupki 123A, 44-313 Wodzisław Śląski, Poland
        </li>
        <li>
          <b>“Newsletter”</b> - The third-party service which lets us collect
          Users' email addresses and names in regard to sending them emails
        </li>
        <li>
          <b>“User”</b> - A person who visits, browses, and interacts with the
          Website or a Newsletter
        </li>
      </ul>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        3. Information collected
      </Text>
      <Text type={TextType.H3} weight={TextFontWeight.bold}>
        Information collected automatically
      </Text>
      <Text type={TextType.PARAGRAPH}>
        By visiting our Website, our servers automatically collect information
        provided by your browser. This data may include information such as
        browser type and version, language preferences, the time spent on
        particular web pages, your location such as country and city, and other
        non-personal information.
      </Text>
      <Text type={TextType.H3}>
        <b>Information you provide us by signing up for the newsletter.</b> Read <Link to="/newsletter-policy">Newsletter Policy</Link>
      </Text>
      <Text type={TextType.PARAGRAPH}>
        To become a subscriber to the Newsletter you will need to fill out a
        signup newsletter form. To subscribe to the newsletter you will need to
        enter your email address and optionally your name. By registering, you are
        authorizing us to collect, store and use your email address and name in
        accordance with this Privacy Policy.
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        4. Use and sharing of information
      </Text>
      <Text type={TextType.PARAGRAPH}>
        We do not sell, trade, rent, or otherwise share for marketing purposes
        your Personal Information with third parties without your consent,
        except as otherwise stated in this Privacy Policy. We use third-party
        services to gather and collect the information described in section 3.
      </Text>
      <Text type={TextType.PARAGRAPH}>
        In general, we use Non-Personal Information (except the data provided in
        the Newsletter form) to help us improve the Service and customize the
        user experience. We also aggregate Non-Personal Information in order to
        track trends and analyze use patterns on the Website.
      </Text>
      <Text type={TextType.PARAGRAPH}>
        This Privacy Policy does not limit our use or disclosure of Non-Personal
        Information and we reserve the right to use and disclose such.
      </Text>
      <Text type={TextType.PARAGRAPH}>
        The only data that might be considered Personal is your email address
        and name, which you can provide us by subscribing to our newsletter and
        thus agreeing to their use.
      </Text>
      <Text type={TextType.PARAGRAPH}>
        Personal or Non-Personal data may be transferred to the following
        entities whose services are used by the Administrator in order to run
        the Website and provide services through it:
      </Text>
      <ul className={"page__list"}>
        <li>
          <b>Data Analysis:</b>
          <br />
          Google LLC. (1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA)
        </li>
        <li>
          <b>Newsletter:</b>
          <br />
          MailerLite Limited, an Irish registered company at Ground Floor, 71
          Lower Baggot Street, Dublin 2, D02 P593, Ireland
        </li>
      </ul>
      <Text type={TextType.PARAGRAPH}>
        We recommend that you check the Website periodically to stay updated on
        how your information is used.
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        5. Protection
      </Text>
      <Text type={TextType.PARAGRAPH}>
        We are using only recommended services for data analysis and newsletter
        purposes.
        <br />
        These services implement suitable security methods. However, we do not
        guarantee that your information will not be accessed, disclosed,
        altered, or destroyed by a breach of such firewalls and secure server
        software. By using our Service, you acknowledge that you understand and
        agree to assume these risks.
      </Text>
      <Text type={TextType.PARAGRAPH} weight={TextFontWeight.bold}>
        User rights regarding the use of personal information
      </Text>
      <Text type={TextType.PARAGRAPH}>
        You have the right at any time to prevent us from contacting you for
        marketing purposes. When we send a promotional communication to a User,
        the User can opt out of further promotional communications by following
        the unsubscribe instructions provided in each promotional email.
      </Text>
      <Text type={TextType.PARAGRAPH} weight={TextFontWeight.bold}>
        Link to other Websites
      </Text>
      <Text type={TextType.PARAGRAPH}>
        As part of the Service, we may provide links to other websites or
        applications that are not owned or controlled by us. Please be aware
        that we are not responsible for the privacy practices employed by those
        websites or the information or content they contain. This Privacy Policy
        applies solely to information collected by us through the Website. We
        encourage you to read the privacy terms of other websites before
        proceeding to use them.
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        6. Changes and amendments
      </Text>
      <Text type={TextType.PARAGRAPH}>
        We reserve the right to change this policy at any
        time. We will notify about our Privacy Policy changes by posting the new Policy on the Website at <Link to='/privacy-policy'>Privacy Policy</Link> or/and by sending you an email
        to the email address you have provided. Please check periodically the Website for updates.
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        7. Cookies
      </Text>
      <Text type={TextType.PARAGRAPH}>
        Our Website uses third-party analytics tools that use 'cookies'. The
        information is used to improve the users' experience and monitor the
        Website and Services performance. You can read more about our{" "}
        <Link to="/cookie-policy">cookie policy</Link>.
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        8. GDPR (RODO)
      </Text>
      <Text type={TextType.PARAGRAPH}>
        In accordance with the GDPR, the User has the following rights in regard
        to the processing of his personal data:
      </Text>
      <ul className={"page__list"}>
        <li>The right to information on how personal data is processed.</li>
        <li>The right to access and correct personal data.</li>
        <li>
          The right to delete personal data. The Administrator may refuse to
          delete data for which he has responsibility for further processing
          (e.g. legal obligation).
        </li>
        <li>
          The right to request the restriction of personal data processing.
        </li>
        <li>
          The right to withdraw the consent if personal data was processed on
          the basis of the user's consent.
        </li>
        <li>The right to transfer personal data.</li>
      </ul>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        9. Contact
      </Text>
      <Text type={TextType.PARAGRAPH}>
         In case of any questions or concerns regarding this Policy or the practices of this Website, we encourage you to contact us at:
      </Text>
      <Text type={TextType.PARAGRAPH}>
        Michał Rożenek<br />
        contact.hellojs@gmail.com<br /><br />
        <i>Last updated on 03/01/2023</i>
      </Text>
    </div>
  )
}

export default PrivacyPolicy
